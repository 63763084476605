.main-div {
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/theme3.webp"); */
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* Use cover to ensure the background image covers the entire viewport */
}

.full-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 2vw;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 5vw;
  width: 95%;
}

.navigate-arrow {
  font-size: 3vw;
  /* Adjust font size relative to viewport width */
  font-weight: 400;
  color: #fff;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
}

.game-name {
  text-align: center;
  font-size: 3vw;
  font-weight: 500;
  color: white;
}

.score {
  font-size: 2vw;
  border: 3px solid #fff;
  padding: 10px 30px;
  border-radius: 25px;
  /* Adjust font size relative to viewport width */
  color: white;
  font-weight: 400;
  margin-top: 13px;
}

.main-body {
  display: flex;
  flex-wrap: wrap;
  gap: 2vw;
  /* Adjust gap between cards relative to viewport width */
  justify-content: center;
  align-items: center;
}

.card {
  perspective: 1000px;
  width: 12vw;
  /* Adjust card width relative to viewport width */
  max-width: 150px;
  /* Set a maximum width for smaller screens */
  height: 25vh;
  /* Adjust card height relative to viewport height */
  max-height: 150px;
  /* Set a maximum height for smaller screens */
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ccc; */
  border-radius: 3%;
}

.card-front {
  background-image: url("../assets/flipCard.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.card-back {
  /* background-color: #2e2e2e;
    color: white; */
  background-image: url("../assets/flipGameBack.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotateY(180deg);
  font-size: 14px;
}

.card.matched .card-inner {
  border: 5px solid green;
  border-radius: 6px;
}

/* Modal Styles */
.btns-modal {
  display: flex;
  margin-top: 35px;
  justify-content: center;
}

.btn {
  padding: 15px 11px;
  width: 46%;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.replay-button {
  background-color: #e60000;
  color: white;
  border: none;
  width: auto;
}

.back-button {
  color: black;
  border: 2.3px solid #000;
  background-color: transparent;
  width: auto;
  align-self: center;
}

.btn:hover {
  opacity: 0.8;
}

.modal-image {
  margin: 12px;
}

.modal-winner {
  font-weight: bold;
  margin-bottom: 3px;
}

.desc-modal {
  color: #262626;
  font-weight: 400;
}

.img-modal {
  margin-bottom: 7px;
}

.btn-modal {
  color: black;
  border: 2.3px solid #000;
  padding-top: 5px;
  padding-bottom: 9px;
  border-radius: 25px;
  width: 70%;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 400;
  background-color: white;
}

@media (max-width: 768px) {
  .header {
    margin: .5rem 4vw 0rem 4vw;
  }

  .navigate-arrow {
    font-size: 5vw;
    border: 1.5px solid #fff;
  }

  .game-name {
    font-size: 5vw;
  }

  .score {
    font-size: 4vw;
    padding: 12px 30px;
  }

  .main-body {
    grid-template-columns: repeat(3, 1fr);
  }

  .card {
    width: 30vw;
    height: 30vh;
  }
}
