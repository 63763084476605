.expectations-div-body{
   
    
}
.game-item{
min-height: 50vh;
    padding: 0 2vw;
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}
#chartdiv{
    height: 15vh;
    width: 50%;
}
.question-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}
.enabled-arrow{
    height: 2rem;
    width: 2rem;
    background-color: #e60000;
    border-radius: 50%;
    color: white;
    margin: 0 2vw;
        display: flex;
            justify-content: center;
            align-items: center;
}
.disabled-arrow {
    height: 2rem;
        width: 2rem;
        border:rgba(0, 0, 0, 0.2) 3px solid;
        border-radius: 50%;
        color: rgba(0, 0, 0, 0.2);
        margin: 0 2vw;
            display: flex;
                justify-content: center;
                align-items: center;
}
.btn-div{
    display: flex;
    justify-content: flex-end;
    padding: 2vw 2vw;
}
.export-btn {
    cursor: pointer;
    color: var(--New-palette-Inverse-Monochrome-Inverse-Monochrome6-Inverse, var(--white, #FFF));
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    width: 15%;
    /* Paragraph/Desktop/Size 2/Weight 2 */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    padding: 2vh 2vw;

    display: flex;

    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--New-palette-Default-Primary-Primary1, #E60000);

    /* 144.444% */
}