.main-div {
  width: 100vw;
  height: 100vh;
  /* background-image: url("../assets/theme3.webp"); */
  background-color: black;
  color: white;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nav-bar {
  position: absolute;
  top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.qr-span {
  text-align: center;
  width: 70%;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 60px;
}

.div-rounded {
  min-width: 100px;
  padding: 10px 15px;
  text-align: center;
  border: 3px solid white;
  border-radius: 28px;
}
