.main-container{
background: var(--New-palette-Default-Monochrome-Monochrome2, #F2F2F2);
min-height: 100vh;
}
.selective-round-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0 2vw;
}
.round-selection-header-title{
display:  flex;
align-items: center;
justify-content: center;
margin: 2vh;
}
.building-vector{
   
    
  
   margin: 0 2vh;
    padding: 0.5rem;
}
.img-bg{
    height: 3rem;
        width: 3rem;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 2vw;
}
.round-selection-header-title-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.round-selection-header-title-div-header{
    color: var(--New-palette-Default-Monochrome-Monochrome5, #262626);
        font-feature-settings: 'liga' off, 'clig' off;
    
        /* Heading/Desktop/Size 2/Weight 3 */
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-right: 2vw;
        /* 114.286% */
}
.round-selection-header-title-div-subheader{
    color: var(--New-palette-Default-Monochrome-Monochrome4, #7E7E7E);
        
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 162.5% */
        letter-spacing: 1px;
}
.drobdown-arrow,.drobdown-arrow:hover,.drobdown-arrow:focus{
    background-color: transparent !important;
    border: none !important;
    color: #262626;
padding: 0;
}
.actions-div{
    display: flex;
    flex-direction: column;
    border-radius: 6px;
        background-color: #fff;
    min-height: 20vh;
 
        /* Drop Shadow/1 */
        box-shadow: 2px 8px 16px 0px rgba(0, 0, 0, 0.04);
        margin: 0 2vw;

}
.actions-div-header{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: #25282B solid 1px;
    padding: 2vh 2vw;
}
.actions-div-header-img{
    margin-right: 2vw;
}
.actions-div-header-name{
    color: var(--New-palette-Default-Primary-Primary4, #25282B);
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        /* 140% */
}
.actions-div-body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 2vh 2vw;
}

.game-name{
    color: var(--New-palette-Default-Monochrome-Monochrome6, #0D0D0D);
        font-feature-settings: 'liga' off, 'clig' off;
    
        /* Paragraph/Desktop/Size 1/Weight 2 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
}
.game-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}
.game2-item{
display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;padding: 7vh 2vh 7vh 2vh;

}
.expectations-item{

}
.reports-maindiv{
    display: flex;
        flex-direction: column;
        border-radius: 6px;
        background-color: #fff;
       
    
        /* Drop Shadow/1 */
        box-shadow: 2px 8px 16px 0px rgba(0, 0, 0, 0.04);
        margin: 2vh 2vw;
}
.reports-div-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: #25282B solid 1px;
    padding: 2vh 2vw;
    align-items: center;
}

.reports-div-header-img {
    margin-right: 2vw;
}

.reports-div-header-name {
    color: var(--New-palette-Default-Primary-Primary4, #25282B);
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-right: 2vw;
    /* 140% */
}
.reports-selection-header{
    display: flex;
    flex-direction:  row;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin-top: 2vh;
}
.reports-div-header-selector {
    color: var(--New-palette-Default-Monochrome-Monochrome6, #0D0D0D);
        font-feature-settings: 'liga' off, 'clig' off;
    
        /* Paragraph/Desktop/Size 1/Weight 2 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding: 1vh 2vw;
        border-radius: 8px;
        border: #25282B 2px solid;
        border-radius: 24px;
        margin-right: 1vw;
        cursor: pointer;
}
.reports-div-header-selector-selected{
    color: white;
    border-radius: 24px;
    background-color: #00697C;
        font-feature-settings: 'liga' off, 'clig' off;
    
        /* Paragraph/Desktop/Size 1/Weight 2 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        padding: 1vh 2vw;
        margin-right: 1vw;
            cursor: pointer;
}
.games-div-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 2vh 0;
}
.games-table-body{
    border-radius: 5px;
        background: var(--white, #FFF);
    min-height: 15vh;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
      
}
.games-table-body-header{
    display: flex;
    flex-direction: row;
    
    border-bottom: rgba(0, 0, 0, 0.25) 2px solid;
    padding: 2vw;
    
}
.games-table-body-footer{
    display: flex;
        flex-direction: row;
    
            padding: 2vw 0 0 2vw;
        justify-content: flex-end;
}
.games-table-body-header-name{
color: var(--New-palette-Default-Primary-Primary1, #E60000);
   width: 50%;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    /* 130% */
    letter-spacing: 1px;
}
.games-table-body-header-content {
    color: var(--New-palette-Default-Primary-Primary4, #25282B);
    width: 50%;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        /* 144.444% */
        letter-spacing: 1px;
        text-transform: capitalize;
}
.games-table-body-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2vh;
    min-height: 50vh;
}
.games-table-body-body-text{
    color: var(--New-palette-Default-Monochrome-Monochrome5, #262626);
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
    
        /* Heading/Desktop/Size 1/Weight 1 */
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        /* 140% */
}
.export-btn{
    cursor: pointer;
    color: var(--New-palette-Inverse-Monochrome-Inverse-Monochrome6-Inverse, var(--white, #FFF));
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
    width:15%;
        /* Paragraph/Desktop/Size 2/Weight 2 */
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        padding: 2vh 2vw;
        
        display: flex;
           
            justify-content: center;
            align-items: center;
            border-radius: 6px;
                background: var(--New-palette-Default-Primary-Primary1, #E60000);
                
        /* 144.444% */
}
.expectations-div-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2vh 2vw;
    min-height: 50vh;

}