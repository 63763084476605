.main-div {
    width: 102vw;
    height: 100vh;
    background-image: url("../../assets/tourLanding.jfif");
    background-size: 100% 100%;
}

.back-btn {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    left: 4%;
    top: 7%;
}


.btn-disabled {
    color: var(--New-palette-Default-Primary-Primary2, var(--white, #FFF));
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-radius: 40px;
    padding: 0.5vh 3.5vw;
    margin: 2vh;
    background-color: gray;
    opacity: 0.9;
    cursor: not-allowed;
    pointer-events: none;
}

.screen-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: flex-start;
     background-color: rgba(103, 95, 95, 0.5);
}

.screen-header-main-text {
    color: var(--New-palette-Inverse-Monochrome-Inverse-Monochrome6-Inverse, var(--white, #FFF));
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Heading/Mobile/Size 2/Weight 3 */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-top: 2%;
    /* 140% */
}

.screen-header-submain-text {
    color: var(--New-palette-Inverse-Monochrome-Inverse-Monochrome6-Inverse, var(--white, #FFF));
    font-feature-settings: 'liga' off, 'clig' off;

    /* Heading/Mobile/Size 1/Weight 2 */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}

.main-div-header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3vh;
    height: 22%;
}

.buildings-boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 61%;
}

.building-box-card-dimmed {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10.95px;
    padding: 2vh 2vh 2vh 2vh;
    border: 1.5px solid var(--white, #FFF);
        /* pointer-events: none; */
        /* Disable all interactions */
        opacity: 0.8;
        /* Lower the opacity to indicate it's disabled */
        filter: grayscale(100%);

    /* Elevation */
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
    cursor: none;
    margin: 0 2vw;
    
}

.building-box-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10.95px;
    padding: 2vh 2vh 2vh 2vh;
    border: 1.5px solid var(--white, #FFF);
    background: var(--New-palette-Default-Transparent-Opacity2, rgba(255, 255, 255, 0.30));

    /* Elevation */
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    margin: 0 2vw;
}

.building-box-card-img {
    border-radius: 8.212px;
    width: 116.026px;
    height: 102.423px;

    flex-shrink: 0;
}

.btn {
    color: var(--New-palette-Default-Primary-Primary2, var(--white, #FFF));
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    background-color: #e60000;
    border-radius: 40px;
    background: var(--New-palette-Default-Primary-Primary1, #E60000);
    padding: 0.5vh 3.5vw;
    margin: 2vh;
    /* 233.333% */
}