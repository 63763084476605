.maindiv {
    padding: 0 1.5rem;
    /* position: relative; */
    overflow: hidden;
    margin: 0 !important;
}

.subParentDiv {
    margin-top: 5.5%;
    border: white 2px solid;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    margin-bottom: 5rem;
    height: 100%;
}

.parent {
    margin: 2rem 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.buttonContainer {

    height: 2.75rem;
    text-align: center;
    align-items: center;
    padding: 0.625rem 0.8125rem 0.625rem 0.75rem;
    border-radius: 0.25rem;

    margin-bottom: 0.5rem;
    margin-right: 0;
}

.buttonas-vodafonerContainer {
    width: 80%;
    height: 2.75rem;
    text-align: center;
    align-items: center;
    padding: 0.625rem 0.8125rem 0.625rem 0.75rem;
    border-radius: 0.25rem;

    margin-top: 0.5rem;
}

.button-login {
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 28px/28px Vodafone Rg;
    letter-spacing: 1px;
    color: #FFFFFF;
    background-color: transparent;
    opacity: 1;
    display: flex;
    font-weight: 400;
    text-shadow: 10px 10px 10px darkred;
    border: 2px white solid;
    border-radius: 8px;
    padding: 0.75rem 2.2rem;
    margin: 0 2rem 2rem 2rem;
    cursor: pointer;

    display: flex;
    justify-content: center;
}

.button-login:hover {
    background-color: #565555;
    text-shadow: none;
    color: white !important;
}

.button-forget {
    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 28px/28px Vodafone Rg;
    letter-spacing: 1px;
    color: #FFFFFF;
    background-color: transparent;
    opacity: 1;
    display: flex;
    font-weight: 400;
    text-shadow: 10px 10px 10px darkred;
    border: 2px white solid;
    border-radius: 8px;
    padding: 0.75rem 0;
    margin: 0 2rem 1rem 1.3rem;
    cursor: pointer;

    display: flex;
    justify-content: center;
}

.button-forget:hover {
    background-color: #565555;
    text-shadow: none;
    color: white !important;
}

.btnText {
    width: 18.3125rem;
    height: 1.5rem;

    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;

    line-height: 1.5;

    text-align: "center";
}

.button-login:hover {
    color: black;
}

.logoContainer {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 1rem;
}

.welcomeClass {
    font-size: 3.75rem;
    -webkit-text-stroke: 0.25px white;
    -webkit-text-fill-color: transparent;
}

.managementMeetingClass {
    font-size: 3rem;
    color: white;
}

.summit-intro-heading {

    text-align: center;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 40px/40px Vodafone Rg;
    letter-spacing: 4px;
    color: #FFFFFF;

    opacity: 1;
    display: flex;
    font-weight: 400;
    text-shadow: 10px 10px 10px darkred;
    display: flex;
    justify-self: center;
    align-self: center;
}

.summit-intro-bold {


    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 40px/40px Vodafone Rg;
    letter-spacing: 4px;
    color: #FFFFFF;
    display: inline !important;
    opacity: 1;
    display: flex;
    font-weight: 900;
    margin-right: 1rem;

}

.btn-container {
    min-width: 14rem;
}

/* NEW DESIGN */

.loginContainerDiv {
    width: 90%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: flex-start;
}

.VOISLogo{
    font-weight: 700;
    font-size: 20px;
    margin-top: 30px;
}

.contentContainer{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 90vh;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    align-self: center;
    height: 50vh;
}

.welcomeToVISIT { font-weight: 700; margin-bottom: 50px; }

.welcomeToVISITDesc {
   width: 60%;
   line-height: 26px;
   margin-bottom: 60px;
}

.loginBtn {
    width: 29%;
    background-color: #e60000;
    color: white;
    border: none;
}

.linksDiv { margin-top: 170px; display: flex; }

.link { color: black;margin-right: 8px }