@font-face {
  font-family: Vodafone RG;
  src: url("../src/assets/fonts/VodafoneRg.ttf");
}

@font-face {
  font-family: Vodafone RG;
  src: url("./assets/fonts/VodafoneRgBd.ttf");

  font-weight: 700;
}

@font-face {
  font-family: Vodafone Lt;
  src: url("../src/assets/fonts/VodafoneLt.ttf");
}

@font-face {
  font-family: Vodafone Lt;
  src: url("./assets/fonts/VodafoneLtBd.ttf");

  font-weight: 700;
}

@font-face {
  font-family: "Polaris";
  src: url("../src/assets/fonts/Polaris.otf");
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
span,
button {
  font-family: "Vodafone RG" ;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) {

  /* Adjust your styles for iPhone-specific sizes */
  body {
    margin: 0;
    padding: 0;
  }
}
a-scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* Lower value */
}