.main-cards {
    display: flex;
    flex-direction: row;
    width: 97%;
}

.page-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 47%;
    z-index: 10000;
}

.viewers {
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    width: 5rem;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 16px;
    align-items: center;
}

.emojis {
    display: flex;
    flex-direction: row;
    background-color: black;
    color: white;
    width: 25rem;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 16px;
    align-items: center;
}

.emojis-conent {
    display: flex;
    flex-direction: row;

    align-items: center;
    position: absolute;
    bottom: 0;
    left: 37%;
    z-index: 10000;
}

.modal {
    position: absolute;
    top: 1%;
    left: 1%;
    z-index: 100001;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.modal-overlay {
    position: absolute;

    z-index: 100002;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.main-div-overlay {
    width: 102vw;
    height: 100vh;
    background-image: url("../../assets/tourLanding.webp");
    background-size: 100% 100%;

}

.modal-overlay,
.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    /* Higher value */
}

.screen-body-overlay {
    background-color: rgba(103, 95, 95, 0.5);

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    padding-bottom: 2vh;
}

.screen-body-overlay-text {
    color: var(--New-palette-Default-Primary-Primary2, var(--white, #FFF));
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Vodafone Rg";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    width: 40%;
    margin: 2vh 0;
    /* 175% */
}

.screen-body-overlay-btn {
    color: var(--New-palette-Default-Primary-Primary2, var(--white, #FFF));
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Vodafone Rg";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    width: 50%;
    margin: 2vh 0;
    /* 175% */
}

.survey-body {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
}

.emoji {
    display: flex;
    flex-direction: row;
    border-right: 1px grey solid;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.emoji-last {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.count {
    margin: 0 0.5rem;
}

@media only screen and (max-width: 991.98px) {
    .emojis-conent {

        left: 0;
    }
}

.modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
}

.header-container {
    border-radius: 0px 0px 8px 8px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(0.5px);
    width: 100%;
    height: 7rem;
    flex-shrink: 0;
    padding: 0 1rem;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 104px;
    height: 38px;
}

.searchDiv {
    float: right;
    width: 65%;
}

.header {
    width: 100%;
}

.footer-container {
    border-radius: 0px 0px 8px 8px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%);
    backdrop-filter: blur(0.5px);
    width: 100%;
    height: 10rem;
    flex-shrink: 0;
    padding: 0 1rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reset-img {
    margin: 1rem 1rem;
}

.back-btn {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    left: 4%;
    top: 7%;
}

.max-btn {
    width: 2rem;
    cursor: pointer;
    position: absolute;
    left: 16%;
    top: 8%;
}

.ham-menu {
    cursor: pointer;
    cursor: pointer;
    position: absolute;
    left: 10%;
    top: 7%;
}

.mode-selection {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    position: absolute;
    right: 20%;
    top: 7%;
    border-radius: 20px;
    border: 1.5px solid var(--white, #FFF);
    background: var(--New-palette-Default-Transparent-Opacity2, rgba(255, 255, 255, 0.30));
}

.mode {
    padding: 1vh 3vh;
    color: var(--New-palette-Default-Primary-Primary2, var(--white, #FFF));
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    width: 50%;
    cursor: pointer;
    /* 233.333% */
}

.mode-selected-3d {
    padding: 1vh 3vh;
    width: 50%;
    color: #e60000;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    /* 233.333% */
}

.mode-selected-2d {
    padding: 1vh 3vh;
    width: 50%;
    color: #e60000;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    background-color: white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    /* 233.333% */
}

.navigator {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 5%;
    top: 7%;
}

.down-arrow {
    transform: rotate(180deg);
}

.nav-banel {
    margin: 2vh;
    width: 96px;
    border-radius: 10.95px;
    border: 1.5px solid var(--white, #FFF);
    background: var(--New-palette-Default-Transparent-Opacity2, rgba(255, 255, 255, 0.30));

    /* Elevation */
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
    height: 70vh;
}

.nav-banel-header {
    border-bottom: 1.5px solid var(--white, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    flex-direction: column;
}

.nav-banel-header-text {
    color: var(--New-palette-Default-Monochrome-Monochrome1, var(--white, #FFF));
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 233.333% */
}

.carousel-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: transform 0.3s ease-in-out;
}

.carousel-item {
    margin: 2vh;

    cursor: pointer;
    border-radius: 50%;
    height: 14.5vh;
    width: 14.5vh;
    /* background-color: red; */
    border: 3px white solid;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh;
    padding: 5vh;
}

.carousel-item-selected {
    margin: 2vh;
    cursor: pointer;
    border-radius: 50%;
    height: 14.5vh;
    width: 14.5vh;
    /* background-color: red; */
    border: 3px white solid;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh;
    padding: 5vh;
    background-color: white;
}

/* .carousel-icon {
width: 35px;
    height: 35px;
} */
.carousel-item-selected img {

    transition: transform 0.3s ease-in-out;

}


.selected-place-name {
    right: 43%;
    position: absolute;
    bottom: 2vh;
    padding: 1vh 4vh;
    border-radius: 20px;
    border: 1.5px solid var(--white, #FFF);
    background: var(--New-palette-Default-Transparent-Opacity2, rgba(255, 255, 255, 0.30));

    /* Elevation */
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
    color: var(--New-palette-Default-Monochrome-Monochrome1, var(--white, #FFF));
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Vodafone Rg";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 233.333% */
}

.border-div {
    border-radius:10px; 
    position:absolute; 
    margin:auto; 
    top:0;
    bottom:0;
    left:0;
    right:0; 
    width:42%;
    height:60%; 
    background-color:transparent; 
    border:solid 2px white;
}
