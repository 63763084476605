.bgColor {
    background-color: #BE2026;
    position:fixed; 
    width: 100%; 
    height: 100vh; 
    overflow:hidden !important;
    z-index: -1;
}

.container {
    background:url("../../public/assets/survey-background.svg") no-repeat;
    background-size: cover;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


.question {
    background-color:#FFCF01;
    color:white;
    border-radius:30px;
     padding:20px; 
     width:86%; 
     box-shadow:5px -5px 0px 0px #FBB817; 
     font-size:18px;

}

.submitBtn {
    width: 45%;
    background-color: transparent;
    padding: 9px 24px 9px 24px;
    border-radius: 25px;
    border: 2px solid white;
    font-weight: 400;
    color: white;

}

.submitBtn:disabled {
    border: 2px solid #ffffff8c;
    color: #ffffff8c;

}