.main-div {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/theme3.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  /* Use cover to ensure the background image covers the entire viewport */
}

.full-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 2vw;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 5vw;
  width: 100%;
}

.game-name {
  font-size: 3vw;
  /* Adjust font size relative to viewport width */
  font-weight: 400;
  color: #e60000;
}
