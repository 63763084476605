.search-input:focus{
    outline: none;
}

button {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 7.6px;
    padding-bottom: 7.6px;
    border-radius: 6px;
}

.add-round-modal {
    color: #E60000;
    border-bottom: 0.05px solid #AFAFAF;
    padding: 10px 15px 10px 15px;
    font-size: 14px;
}

.modal-input{
    outline: none;
    border:  1px solid #AFAFAF;
    border-radius: 5px;
}

.error-msg {
    color: #E60000;
    font-weight: 500;
}