button:hover {
    cursor: pointer;
}

.hide-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    width: 0; /* For Chrome, Safari, and Opera */
    display: none;
  }
}

.MuiTablePagination-displayedRows {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
