/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
  /* max-width: 500px; */

  /* align-items: center;
  display: grid;
  justify-content: center; */
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  cursor: pointer;
  width: 90%;
  position: relative;
  padding-inline-start: 30px;
  border-radius: 40px;
}
.slide-deactivated{
  display: inline-block;
    cursor: pointer;
    width: 90%;
    position: relative;
    padding-inline-start: 30px;
    border-radius: 40px;
    /* pointer-events: none; */
      /* Disable all interactions */
      opacity: 0.5;
      /* Lower the opacity to indicate it's disabled */
      filter: grayscale(100%);
      /* Optional: Make it look gray */
      /* cursor: not-allowed; */
}
.img-active {
  height: auto;
  width: 99%;
}

.comingSoon{
  position: absolute;
  right: 15px;
  top: 20px;
  background-color: white;
  border-radius: 20px;
}


.img-slide {
  height: auto;
  margin-bottom: 50px;
  width: 80%;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 3px 3px;
  background-color: #7E7E7E;
}

.slideshowDot.active {
  background-color: #E60000;
  width: 12px;
  height: 12px;
  margin: 15px 3px 0px;
}

.slideshowDot.abActive {
  width: 8px;
  height: 8px;
  margin: 15px 0px 1px;
}